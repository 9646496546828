@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap";
.manrope {
  font-optical-sizing: auto;
  font-family: Manrope, system-ui;
  font-style: normal;
}

.inter {
  font-optical-sizing: auto;
  font-family: Inter, system-ui;
  font-style: normal;
}
/*# sourceMappingURL=index.57592e8e.css.map */
